
import Typed from 'typed.js';

var options = {
    strings: ['Дадим лучшие цены на остекление балконов и лоджий'],
    showCursor: false,
    typeSpeed: 60,

};

var typed = new Typed('.container__title.typed', options);
