$( ".header__wrapper_menu-item-click" ).click(function() {
$(".header__wrapper_box-slide").stop().fadeToggle()
});

  $('.header__mobile-burger_item').click(function(){
      $('.advantages').addClass('mrg-top');
      $('.header__wrapper_menu-moble-box').stop().slideToggle()

        $(this).toggleClass('open');

      if ($(this).hasClass("open")) {
          $(".header").addClass('fixed')
      }

      else{
          $(".header").removeClass('fixed')
      }

  });
$(".href-useful").click(function (){
    $('html, body').animate({
        scrollTop: $(".useful").offset().top
    }, 500);
});
$(".href-calculator").click(function (){
    $('html, body').animate({
        scrollTop: $(".calculator").offset().top
    }, 500);
});
$(".href-index").click(function (){
    $('html, body').animate({
        scrollTop: $(".header__information").offset().top
    }, 500);
});
$(".href-solutions").click(function (){
    $('html, body').animate({
        scrollTop: $(".solutions").offset().top
    }, 500);
});

$(".href-projects").click(function (){
    $('html, body').animate({
        scrollTop: $(".projects").offset().top
    }, 500);
});
$(".href-footer").click(function (){
    $('html, body').animate({
        scrollTop: $(".footer").offset().top
    }, 500);
});

